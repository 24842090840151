var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-4"
  }, [_c('i', {
    staticClass: "fa fa-backward"
  }), _c('router-link', {
    attrs: {
      "to": {
        name: _vm.BookPath.name
      }
    }
  }, [_vm._v(" Ebooks ")])], 1)], 1), !_vm.item.id && !_vm.isLoading ? _c('b-row', [_c('h3', {
    staticClass: "col text-center"
  }, [_vm._v("Item Not Found")])]) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form', {
    attrs: {
      "id": "form-edit"
    },
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('b-card', {
    attrs: {
      "header": _vm.item.name + ' #' + _vm.item.id,
      "header-bg-variant": "primary",
      "header-tag": "h5",
      "header-class": "font-weight-bold"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-display",
      "label": "Display Name (Item’s Title)",
      "label-for": "display"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "display",
      "type": "text",
      "placeholder": "Enter Display Name",
      "required": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-code",
      "label": "Edition Code",
      "label-for": "code"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "code",
      "type": "text",
      "placeholder": "Enter Edition Code",
      "required": ""
    },
    model: {
      value: _vm.form.edition_code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "edition_code", $$v);
      },
      expression: "form.edition_code"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-slug",
      "label": "Slug",
      "label-for": "slug"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "slug",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.slug,
      callback: function ($$v) {
        _vm.$set(_vm.form, "slug", $$v);
      },
      expression: "form.slug"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Item Status"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "currency",
      "options": _vm.itemStatusOptions,
      "required": ""
    },
    model: {
      value: _vm.form.item_status,
      callback: function ($$v) {
        _vm.$set(_vm.form, "item_status", $$v);
      },
      expression: "form.item_status"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-issue",
      "label": "Issue Number",
      "label-for": "issue"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "issue",
      "type": "text",
      "disabled": _vm.userData.role_id === 5,
      "placeholder": "Enter Issue Number",
      "required": ""
    },
    model: {
      value: _vm.form.issue_number,
      callback: function ($$v) {
        _vm.$set(_vm.form, "issue_number", $$v);
      },
      expression: "form.issue_number"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Author"
    }
  }, [_c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Enter Author",
      "open-direction": "bottom",
      "options": _vm.authorSearchOptions,
      "searchable": true,
      "loading": _vm.isAuthorSearch,
      "close-on-select": true,
      "multiple": true
    },
    on: {
      "search-change": _vm.authorFind,
      "input": _vm.authorSelected
    },
    model: {
      value: _vm.form.author,
      callback: function ($$v) {
        _vm.$set(_vm.form, "author", $$v);
      },
      expression: "form.author"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('label', [_vm._v("Languages")]), _c('multiselect', {
    attrs: {
      "placeholder": "Enter languages",
      "open-direction": "bottom",
      "label": "name",
      "track-by": "name",
      "custom-label": _vm.nameWithISO,
      "options": _vm.languagesSearchOptions,
      "searchable": true,
      "close-on-select": true,
      "multiple": true,
      "required": ""
    },
    model: {
      value: _vm.form.languages,
      callback: function ($$v) {
        _vm.$set(_vm.form, "languages", $$v);
      },
      expression: "form.languages"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Countries"
    }
  }, [_c('multiselect', {
    attrs: {
      "placeholder": "Enter Countries",
      "open-direction": "bottom",
      "label": "name",
      "track-by": "name",
      "custom-label": _vm.nameWithISO,
      "options": _vm.countriesSearchOptions,
      "loading": _vm.isCountriesSearch,
      "searchable": true,
      "close-on-select": true,
      "multiple": true
    },
    model: {
      value: _vm.form.countries,
      callback: function ($$v) {
        _vm.$set(_vm.form, "countries", $$v);
      },
      expression: "form.countries"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Categories"
    }
  }, [_c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Enter Categories",
      "open-direction": "bottom",
      "options": _vm.categoriesSearchOptions,
      "loading": _vm.isCategoriesSearch,
      "searchable": true,
      "close-on-select": true,
      "multiple": true
    },
    on: {
      "search-change": _vm.categoriesFind
    },
    model: {
      value: _vm.form.categories,
      callback: function ($$v) {
        _vm.$set(_vm.form, "categories", $$v);
      },
      expression: "form.categories"
    }
  })], 1)], 1)], 1)], 1), _c('b-card', {
    attrs: {
      "header": "Upload Asset",
      "header-bg-variant": "primary",
      "header-tag": "h5",
      "header-class": "font-weight-bold"
    }
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('b-form-file', {
    attrs: {
      "placeholder": "Choose a file or drop it here...",
      "drop-placeholder": "Drop file here...",
      "accept": ".pdf, .epub"
    },
    model: {
      value: _vm.form.file_content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "file_content", $$v);
      },
      expression: "form.file_content"
    }
  })], 1), _c('b-col', [_vm.form.file_content ? _c('b-button', {
    attrs: {
      "pill": "",
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.onUpload
    }
  }, [_c('i', {
    staticClass: "fa fa-upload"
  }), _vm._v(" Upload")]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "lg": "1"
    }
  })], 1), _c('div', [_vm._v("Max Size 150Mb")]), _c('b-row', [_c('b-col', [_vm._v(" File Size: " + _vm._s(_vm._f("fileSizeFormat")(_vm.form.file_content ? _vm.form.file_content.size : 0)) + " ")]), _c('b-col', [_vm._v(" Current File Size: ( " + _vm._s(_vm._f("fileSizeFormat")(_vm.form.current_size ? _vm.form.current_size : 0)) + " ) ")])], 1)], 1), _c('b-card', {
    attrs: {
      "header": "Detail",
      "header-bg-variant": "primary",
      "header-tag": "h5",
      "header-class": "font-weight-bold"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('DatetimeCustom', {
    attrs: {
      "label": 'Release Date (GMT ' + _vm.timezoneString() + ')'
    },
    model: {
      value: _vm.form.release_date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "release_date", $$v);
      },
      expression: "form.release_date"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('DatetimeCustom', {
    attrs: {
      "label": 'Release Schedule (GMT ' + _vm.timezoneString() + ')'
    },
    model: {
      value: _vm.form.schedule_date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "schedule_date", $$v);
      },
      expression: "form.schedule_date"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-currency",
      "label": "Printed Price Currency",
      "label-for": "currency"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "currency",
      "options": _vm.currencys,
      "required": ""
    },
    model: {
      value: _vm.form.currency,
      callback: function ($$v) {
        _vm.$set(_vm.form, "currency", $$v);
      },
      expression: "form.currency"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-price",
      "label": "Printed Price",
      "label-for": "price"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "price",
      "type": "number",
      "placeholder": "1.200.000",
      "step": "any",
      "required": ""
    },
    model: {
      value: _vm.form.price,
      callback: function ($$v) {
        _vm.$set(_vm.form, "price", $$v);
      },
      expression: "form.price"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-content",
      "label": "Content Type",
      "label-for": "content"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "content",
      "options": _vm.contents,
      "required": ""
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-parental",
      "label": "Parental Control",
      "label-for": "parental"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "parental",
      "options": _vm.parentals,
      "required": ""
    },
    model: {
      value: _vm.form.parental,
      callback: function ($$v) {
        _vm.$set(_vm.form, "parental", $$v);
      },
      expression: "form.parental"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-distribution",
      "label": "Distribution Countries",
      "label-for": "distribution"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "distribution",
      "options": _vm.distributionSearchOptions,
      "disabled": ""
    },
    model: {
      value: _vm.form.distribution,
      callback: function ($$v) {
        _vm.$set(_vm.form, "distribution", $$v);
      },
      expression: "form.distribution"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-reading",
      "label": "Reading Direction",
      "label-for": "reading"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "reading",
      "options": _vm.readings,
      "required": ""
    },
    model: {
      value: _vm.form.reading,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reading", $$v);
      },
      expression: "form.reading"
    }
  })], 1)], 1), !_vm.isProfileVendor ? _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Category Ax"
    }
  }, [_c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Category Ax",
      "open-direction": "bottom",
      "options": _vm.axSearchOptions,
      "searchable": true,
      "loading": _vm.isAxSearch,
      "close-on-select": true,
      "multiple": false,
      "disabled": ""
    },
    on: {
      "search-change": _vm.axFind
    },
    model: {
      value: _vm.form.category_ax,
      callback: function ($$v) {
        _vm.$set(_vm.form, "category_ax", $$v);
      },
      expression: "form.category_ax"
    }
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": "true",
      "unchecked-value": "false"
    },
    model: {
      value: _vm.form.is_active,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is_active", $$v);
      },
      expression: "form.is_active"
    }
  }, [_vm._v(" Active ")]), !_vm.isProfileVendor ? _c('b-form-checkbox', {
    attrs: {
      "value": "true",
      "unchecked-value": "false"
    },
    model: {
      value: _vm.form.is_internal_content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is_internal_content", $$v);
      },
      expression: "form.is_internal_content"
    }
  }, [_vm._v(" Internal Content ")]) : _vm._e()], 1), _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "pt-2"
  })])], 1)], 1), _c('b-card', {
    attrs: {
      "header": "Optional",
      "header-bg-variant": "primary",
      "header-tag": "h5",
      "header-class": "font-weight-bold"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-isbn",
      "label": "International Standard Book Number (ISBN)",
      "label-for": "isbn"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "isbn",
      "type": "text",
      "placeholder": "Enter ISBN"
    },
    model: {
      value: _vm.form.isbn,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isbn", $$v);
      },
      expression: "form.isbn"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-description",
      "label": "Description",
      "label-for": "description"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "description",
      "placeholder": "Enter something...",
      "rows": "6"
    },
    model: {
      value: _vm.form.description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-button', {
    staticClass: "fix-bot-right",
    attrs: {
      "size": "lg",
      "variant": "primary",
      "type": "submit",
      "form": "form-edit",
      "hidden": _vm.isLoading
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }, [_vm._v(" Save")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }